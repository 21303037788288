<template>
  <div class="report">
    <el-row type="flex" justify="center" style="margin-top: 16px">
      <el-col :span="24" class="back_action_panel">
        <el-page-header @back="handleBack" :content="title">
        </el-page-header>
        <el-button class="back_action_panel--first_element" @click="handleSave" type="text">
          <SaveIcon/>
          Сохранить
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" type="flex" justify="center">
      <el-col :span="24">
        <el-form :rules="rules" v-loading="loading" ref="reportForm" :model="report"
                 label-width="300px">
          <el-tabs v-model="active_main_tab" type="border-card" @tab-click="handleTabClick">
            <el-tab-pane label="Общие сведения" :style="{ height: cardHeight}" name="info">
              <el-form-item prop="name" label="Наименование отчета:">
                <el-input v-model="report.name" id="report_name"></el-input>
              </el-form-item>
              <el-form-item prop="roleGroupId" label="Настройка доступа:" v-if="canPublish">
                <el-select v-model="report.roleGroupId" placeholder="Выбрать">
                  <el-option label="Доступен всем"
                             value="00000000-0000-0000-0000-000000000000">
                  </el-option>
                  <el-option v-for="item in roleGroups"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="published" label="Опубликовать" v-if="canPublish">
                <div id="report_publish">
                  <el-switch v-model="report.published"></el-switch>
                </div>
              </el-form-item>

              <el-form-item label="Предоставить доступ" v-if="!this.isNew">
                <el-row align="top" class="mt-0">
                  <el-col :span="12">
                    <el-select v-model="report.allowedOrganizations"
                               placeholder="Выбрать"
                               multiple
                               collapse-tags
                               filterable
                               clearable
                               :loading="loading"
                               :disabled="!allowedOrganizationEdit"
                    >
                      <el-option
                        v-for="item in allowedOrganizationsWithoutOwnDC"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="3" class="ml-1">
                    <el-button class="back_action_panel--first_element"
                               @click="allowedOrganizationEditHandler"
                               type="text"
                               v-if="!allowedOrganizationEdit"
                    >
                      <i class="el-icon-edit"></i>
                      Редактировать
                    </el-button>
                  </el-col>
                  <el-col :span="3" class="ml-1">
                    <el-button class="back_action_panel--first_element"
                               @click="allowedOrganizationSaveHandler"
                               type="text"
                               v-if="allowedOrganizationEdit"
                    >
                      <SaveIcon/>
                      Сохранить
                    </el-button>
                    <el-button class="back_action_panel--first_element"
                               @click="allowedOrganizationCancelHandler"
                               type="text"
                               v-if="allowedOrganizationEdit"
                    >
                      <CancelIcon/>
                      Отмена
                    </el-button>
                  </el-col>
                  <el-col :span="3" class="ml-1">
                  </el-col>
                </el-row>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="Данные" :style="{ height: cardHeight}" name="data">
              <el-form-item prop="tables" label="Выбор таблиц:">
                <el-select v-model="report.meta.tables" multiple filterable size="large"
                           id="table_selector">
                  <el-option v-for="item in dbSchema.tables"
                             :key="item.fullName"
                             :label="item.displayName"
                             :value="item.fullName">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="Настройка полей:">
                <el-table :data="report.meta.columns"
                          style="width: 100%" :max-height="600">
                  <el-table-column label="Таблица" prop="tableDisplayName">
                  </el-table-column>
                  <el-table-column label="Наименование" prop="displayNameWithDescription">
                  </el-table-column>
                  <el-table-column label="Использование">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.reportDataMode" size="mini"
                                 popper-class="using_popover">
                        <el-option label="Не используется"
                                   value="">
                        </el-option>
                        <el-option label="Данные отчета"
                                   value="data">
                        </el-option>
                        <el-option label="Заголовок столбцов"
                                   value="column_header">
                        </el-option>
                        <el-option label="Заголовок строк"
                                   value="row_header">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Фильтрация">
                    <template slot-scope="scope">
                      <el-switch v-if="scope.row.supportsFiltering"
                                 v-model="scope.row.filter"></el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column label="Агрегация">
                    <template slot-scope="scope">
                      <el-select v-if="scope.row.reportDataMode === 'data'"
                                 v-model="scope.row.aggregate" size="mini"
                                 popper-class="agregation_popover">
                        <el-option label="Не используется"
                                   value="">
                        </el-option>
                        <el-option v-if="scope.row.supportsGrouping" label="Группировка"
                                   value="group">
                        </el-option>
                        <el-option v-if="scope.row.supportsCount" label="Количество"
                                   value="count">
                        </el-option>
                        <el-option v-if="scope.row.supportsCount" label="Количество (различных)"
                                   value="countDistinct">
                        </el-option>
                        <el-option v-if="scope.row.supportsSumAvg" label="Сумма"
                                   value="sum">
                        </el-option>
                        <el-option v-if="scope.row.supportsMinMax" label="Минимум"
                                   value="min">
                        </el-option>
                        <el-option v-if="scope.row.supportsMinMax" label="Максимум"
                                   value="max">
                        </el-option>
                        <el-option v-if="scope.row.supportsSumAvg" label="Среднее"
                                   value="avg">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="Расширенная настройка данных" :style="{ height: cardHeight}"
                         name="data_setting">
              <el-tabs tab-position="top" @tab-click="handleClick">
                <el-tab-pane label="Данные отчета">
                  <el-form-item prop="dataSql" label="Запрос (по умолчанию):">
                    <el-input type="textarea"
                              autosize readonly
                              v-model="report.meta.dataSql">
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="dataCustomSql" label="Запрос (настройка):">
                    <el-input type="textarea"
                              autosize clearable
                              v-model="report.meta.dataCustomSql" id="report_data--request_setting">
                    </el-input>
                  </el-form-item>
                </el-tab-pane>
                <el-tab-pane v-if="hasColumnHeaderColumns" label="Заголовки столбцов">
                  <el-form-item prop="columnHeaderSql" label="Запрос (по умолчанию):">
                    <el-input type="textarea"
                              autosize readonly
                              v-model="report.meta.columnHeaderSql">
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="columnHeaderCustomSql" label="Запрос (настройка):">
                    <el-input type="textarea"
                              autosize clearable
                              v-model="report.meta.columnHeaderCustomSql">
                    </el-input>
                  </el-form-item>
                </el-tab-pane>
                <el-tab-pane v-if="hasRowHeaderColumns" label="Заголовки строк">
                  <el-form-item prop="rowHeaderSql" label="Запрос (по умолчанию):">
                    <el-input type="textarea"
                              autosize readonly
                              v-model="report.meta.rowHeaderSql">
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="rowHeaderCustomSql" label="Запрос (настройка):">
                    <el-input type="textarea"
                              autosize clearable
                              v-model="report.meta.rowHeaderCustomSql">
                    </el-input>
                  </el-form-item>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="Фильтр" name="filterTab" :style="{ height: cardHeight}">
              <el-form-item v-if="filterColumns.filter(x=>x.filterIncludeInReport).length>0"
                            prop="filterSummaryDisplayName" label="Заголовок в отчете">
                <el-input v-model="report.meta.filterSummaryDisplayName">
                </el-input>
              </el-form-item>
              <el-table :data="filterColumns"
                        style="width: 100%">
                <el-table-column label="Таблица" prop="tableDisplayName">
                </el-table-column>
                <el-table-column label="Наименование" prop="displayNameWithDescription">
                </el-table-column>
                <el-table-column label="Отображение">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.filterShow"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="Положение" prop="filterOrder">
                  <template slot-scope="scope">
                    <el-button @click="handleFilterUp(scope.row)">
                      <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button @click="handleFilterDown(scope.row)">
                      <i class="el-icon-arrow-down"></i>
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="Отображаемое название">
                  <template slot-scope="scope">
                    <el-input size="mini" v-if="scope.row.filterShow"
                              v-model="scope.row.filterDisplayName"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="Тип фильтра">
                  <template slot-scope="scope">
                    <el-select v-if="scope.row.supportsFilterEqualsOrIn"
                               v-model="scope.row.filterType" size="mini">
                      <el-option label='Диапазон ("с  по")'
                                 value="between">
                      </el-option>
                      <el-option label="Равенство"
                                 value="equals">
                      </el-option>
                    </el-select>
                    <span v-else>{{ scope.row.filterDescription }}</span>
                    <el-button v-if="scope.row.supportsFilterCatalogSql"
                               @click="handleFilterSqlOpen(scope.row)" type="text"
                               icon="el-icon-setting"></el-button>
                  </template>
                </el-table-column>
                <el-table-column label="Значение по умолчанию">
                  <el-table-column label="Использовать">
                    <template slot-scope="scope">
                      <el-switch v-model="scope.row.filterHasDefaultValue"
                                 @change="handleFilterHasDefaultChange(scope.row)"></el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column label="Значение">
                    <template slot-scope="scope">
                      <div v-if="scope.row.filterHasDefaultValue">
                        <div>
                          <el-select v-model="scope.row.filterDefaultIsNullValue" size="mini">
                            <el-option label='Конкретное значение'
                                       value=''>
                            </el-option>
                            <el-option label='Любое значение'
                                       value='not null'>
                            </el-option>
                            <el-option label='Значение отсутствует'
                                       value='null'>
                            </el-option>
                          </el-select>
                        </div>
                        <div v-if="!scope.row.filterDefaultIsNullValue">
                          <div v-if="scope.row.filterType=='in'">
                            <div v-if="getCatalogException(scope.row.fullName)" class="error">
                              {{ getCatalogException(scope.row.fullName) }}
                            </div>
                            <!--el-select v-else v-model="scope.row.filterDefaultMultiValue" multiple filterable size="mini" popper-class="search-select">
                              <el-option v-for="catalogItem in getCatalogItems(scope.row.fullName)"
                                         :key="catalogItem.id"
                                         :label="catalogItem.name"
                                         :value="catalogItem.id">
                              </el-option>
                            </el-select-->
                            <search-input
                              v-model="scope.row.filterDefaultMultiValue"
                              multiple
                              :searchList=scope.row.catalog
                              return-prop="id"
                              label="name"
                              size="large"
                              :allow-create="false"
                              :show-when-init="true"
                              :start-length="scope.row.catalog.length > 200 ? 3 : 0"
                            />
                          </div>
                          <div v-if="scope.row.filterType=='like'">
                            <el-input v-model="scope.row.filterDefaultSingleValue"></el-input>
                          </div>
                          <div v-if="scope.row.filterType=='equals'">
                            <el-date-picker v-if="scope.row.dataType=='datetime'"
                                            v-model="scope.row.filterDefaultSingleValue"
                                            format="dd.MM.yyyy" placeholder="дд.мм.гггг"
                                            v-mask="'##.##.####'" size="mini"
                                            :picker-options="datePickerOptions"></el-date-picker>
                            <el-radio-group v-else-if="scope.row.dataType=='boolean'"
                                            v-model="scope.row.filterDefaultSingleValue">
                              <el-radio :label="null">Любой</el-radio>
                              <el-radio :label="'true'">Да</el-radio>
                              <el-radio :label="'false'">Нет</el-radio>
                            </el-radio-group>
                            <el-input-number v-else-if="scope.row.dataType=='numeric'"
                                             v-model="scope.row.filterDefaultSingleValue"
                                             size="mini"></el-input-number>
                            <el-input v-else v-model="scope.row.filterDefaultSingleValue"
                                      size="mini"></el-input>
                          </div>
                          <div v-if="scope.row.filterType=='between'">
                            <el-date-picker v-if="scope.row.dataType=='datetime'"
                                            v-model="scope.row.filterDefaultFromValue"
                                            format="dd.MM.yyyy" placeholder="дд.мм.гггг"
                                            v-mask="'##.##.####'" size="mini" style="width: 75%"
                                            :picker-options="datePickerOptions"></el-date-picker>
                            <el-input-number v-else-if="scope.row.dataType=='numeric'"
                                             v-model="scope.row.filterDefaultFromValue"
                                             size="mini"></el-input-number>
                            <el-input v-else v-model="scope.row.filterDefaultFromValue"
                                      size="mini"></el-input>
                            <span>&nbsp;-&nbsp;</span>
                            <el-date-picker v-if="scope.row.dataType=='datetime'"
                                            v-model="scope.row.filterDefaultToValue"
                                            format="dd.MM.yyyy" placeholder="дд.мм.гггг"
                                            v-mask="'##.##.####'" size="mini" style="width: 75%"
                                            :picker-options="datePickerOptions"></el-date-picker>
                            <el-input-number v-else-if="scope.row.dataType=='numeric'"
                                             v-model="scope.row.filterDefaultToValue"
                                             size="mini"></el-input-number>
                            <el-input v-else v-model="scope.row.filterDefaultToValue"
                                      size="mini"></el-input>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="Вывод в отчет">
                  <template slot-scope="scope">
                    <el-switch v-if="scope.row.filterShow"
                               v-model="scope.row.filterIncludeInReport"></el-switch>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="Настройка отображения" :style="{ height: cardHeight}"
                         name="view_setting">
              <el-tabs tab-position="top">
                <el-tab-pane label="Данные отчета" class="label--width-middle">
                  <el-form-item prop="showDataTitles" label="Отображать названия столбцов">
                    <el-switch v-model="report.meta.showDataTitles"></el-switch>
                  </el-form-item>
                  <el-form-item v-if="reportDataColumns.filter(x=>x.calcColumnTotal).length>0"
                                prop="columnTotalDisplayName"
                                label="Отображаемое название итоговой строки">
                    <el-input v-model="report.meta.columnTotalDisplayName"
                              id="total_display_name"></el-input>
                  </el-form-item>
                  <el-form-item v-if="reportDataColumns.filter(x=>x.calcRowTotal).length>0"
                                prop="rowTotalPosition" label="Положение итоговых столбцов">
                    <el-select v-model="report.meta.rowTotalPosition">
                      <el-option label="Справа"
                                 value="right_cell">
                      </el-option>
                      <el-option label="Слева"
                                 value="left_cell">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="reportDataColumns.filter(x=>x.calcColumnTotal).length>0"
                                prop="columnTotalPosition" label="Положение итоговой строки">
                    <el-select v-model="report.meta.columnTotalPosition" id="column_total_position"
                               popper-class="column_total_position">
                      <el-option label="Снизу"
                                 value="right_cell">
                      </el-option>
                      <el-option label="Сверху"
                                 value="left_cell">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-table :data="reportDataColumns"
                            style="width: 100%"
                            max-height="450">
                    <el-table-column label="Таблица" prop="tableDisplayName" min-width="150" fixed
                                     header-align="center">
                    </el-table-column>
                    <el-table-column label="Наименование" prop="displayNameWithDescription"
                                     min-width="150" fixed header-align="center">
                    </el-table-column>
                    <el-table-column label="Агрегация" prop="aggregate" min-width="150"
                                     header-align="center">
                      <template slot-scope="scope">
                        <span v-if="scope.row.aggregate=='group'">Группировка</span>
                        <span v-if="scope.row.aggregate=='count'">Количество</span>
                        <span
                          v-if="scope.row.aggregate=='countDistinct'">Количество (различных)</span>
                        <span v-if="scope.row.aggregate=='sum'">Сумма</span>
                        <span v-if="scope.row.aggregate=='min'">Минимум</span>
                        <span v-if="scope.row.aggregate=='max'">Максимум</span>
                        <span v-if="scope.row.aggregate=='avg'">Среднее</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Отображение" prop="show" min-width="130"
                                     header-align="center">
                      <template slot-scope="scope">
                        <el-switch v-model="scope.row.show"></el-switch>
                      </template>
                    </el-table-column>
                    <el-table-column label="Положение" prop="showOrder" min-width="130"
                                     header-align="center">
                      <template slot-scope="scope">
                        <el-button v-if="scope.row.show" @click="handleShowUp(scope.row, 'data')">
                          <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button v-if="scope.row.show" @click="handleShowDown(scope.row, 'data')">
                          <i class="el-icon-arrow-down"></i>
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="Итоговые значения" header-align="center">
                      <el-table-column label="По строкам" prop="calcRowTotal" header-align="center">
                        <template slot-scope="scope">
                          <el-switch
                            v-if="(scope.row.dataType=='numeric' || (scope.row.aggregate && (scope.row.aggregate!='group'))) && scope.row.show"
                            v-model="scope.row.calcRowTotal"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="Отображаемое название" prop="rowTotalDisplayName"
                                       min-width="150" header-align="center">
                        <template slot-scope="scope">
                          <el-input size="mini" v-if="scope.row.calcRowTotal && scope.row.show"
                                    v-model="scope.row.rowTotalDisplayName"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column label="% от итога по строке" prop="calcRowPercent"
                                       header-align="center">
                        <template slot-scope="scope">
                          <el-switch v-if="scope.row.show"
                                     v-model="scope.row.calcRowPercent"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="По столбцам" prop="calcColumnTotal" min-width="100"
                                       header-align="center">
                        <template slot-scope="scope">
                          <el-switch
                            v-if="(scope.row.dataType=='numeric' || (scope.row.aggregate && (scope.row.aggregate!='group'))) && scope.row.show"
                            v-model="scope.row.calcColumnTotal"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="% от итога по столбцу" prop="calcColumnPercent"
                                       header-align="center">
                        <template slot-scope="scope">
                          <el-switch v-if="scope.row.show"
                                     v-model="scope.row.calcColumnPercent"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="% в итовой строке" prop="calcRowTotalPercent"
                                       header-align="center">
                        <template slot-scope="scope">
                          <el-switch
                            v-if="scope.row.calcRowTotal && scope.row.calcColumnTotal && scope.row.show"
                            v-model="scope.row.calcRowTotalPercent"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="% в итовом столбце" prop="calcColumnTotalPercent"
                                       header-align="center">
                        <template slot-scope="scope">
                          <el-switch
                            v-if="scope.row.calcRowTotal && scope.row.calcColumnTotal && scope.row.show"
                            v-model="scope.row.calcColumnTotalPercent"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="% положение" prop="percentPosition" width="150"
                                       header-align="center">
                        <template slot-scope="scope">
                          <el-select
                            v-if="(scope.row.calcRowPercent || scope.row.calcColumnPercent) && scope.row.show"
                            v-model="scope.row.percentPosition" size="mini"
                            popper-class="persent_position">
                            <el-option label="В текущей ячейке"
                                       value="same_cell">
                            </el-option>
                            <el-option label="В ячейке слева"
                                       value="left_cell">
                            </el-option>
                            <el-option label="В ячейке справа"
                                       value="right_cell">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column label="% отображаемое название" prop="percentDisplayName"
                                       width="150" header-align="center">
                        <template slot-scope="scope">
                          <el-input size="mini"
                                    v-if="(scope.row.calcRowPercent || scope.row.calcColumnPercent) && (scope.row.percentPosition != 'same_cell') && scope.row.show"
                                    v-model="scope.row.percentDisplayName"></el-input>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column v-if="!hasColumnHeaderColumns && !hasRowHeaderColumns"
                                     label="Сортировка значений" header-align="center">
                      <el-table-column label="Направление" min-width="180" header-align="center">
                        <template slot-scope="scope">
                          <el-select v-if="scope.row.supportsSorting || scope.row.aggregate"
                                     v-model="scope.row.showSort" size="mini"
                                     popper-class="sort_direction">
                            <el-option label="Не используется"
                                       value="">
                            </el-option>
                            <el-option label="Основная, по возрастанию"
                                       value="primary_asc">
                            </el-option>
                            <el-option label="Основная, по убыванию"
                                       value="primary_desc">
                            </el-option>
                            <el-option label="Дополнительная, по возрастанию"
                                       value="secondary_asc">
                            </el-option>
                            <el-option label="Дополнительная, по убыванию"
                                       value="secondary_desc">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column label="Пустые в начале" header-align="center">
                        <template slot-scope="scope">
                          <el-switch v-if="scope.row.showSort"
                                     v-model="scope.row.showSortEmptyFirst"></el-switch>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column v-if="report.meta.showDataTitles" label="Отображаемое название"
                                     min-width="500" header-align="center">
                      <template slot-scope="scope">
                        <el-input size="mini" v-model="scope.row.showDisplayName"></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="hasColumnHeaderColumns" class="label--width-middle"
                             label="Заголовки столбцов">
                  <el-form-item prop="showColumnHeaderTitles" label="Отображать названия столбцов">
                    <el-switch v-model="report.meta.showColumnHeaderTitles"></el-switch>
                  </el-form-item>
                  <el-form-item prop="columnHeaderShowEmpty"
                                label="Отображать пустые (нулевые) значения">
                    <el-switch v-model="report.meta.columnHeaderShowEmpty"></el-switch>
                  </el-form-item>
                  <el-table :data="columnHeaderColumns"
                            style="width: 100%" max-height="450">
                    <el-table-column label="Таблица" prop="tableDisplayName" header-align="center"
                                     fixed min-width="130">
                    </el-table-column>
                    <el-table-column label="Наименование" prop="displayNameWithDescription"
                                     header-align="center" fixed min-width="130">
                    </el-table-column>
                    <el-table-column label="Отображение" prop="show" header-align="center"
                                     min-width="120">
                      <template slot-scope="scope">
                        <el-switch v-model="scope.row.show"></el-switch>
                      </template>
                    </el-table-column>
                    <el-table-column label="Группировка" header-align="center" min-width="180">
                      <template slot-scope="scope">
                        <el-select v-if="scope.row.show" v-model="scope.row.showGrouping"
                                   size="mini" popper-class="grouping_type">
                          <el-option label="Не используется"
                                     value="">
                          </el-option>
                          <el-option label="Объединение"
                                     value="merged">
                          </el-option>
                          <el-option label="Заголовок"
                                     value="header">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column label="Подытог" header-align="center">
                      <el-table-column label="Вычислить" header-align="center"
                                       prop="calcGroupingTotal" min-width="100">
                        <template slot-scope="scope">
                          <el-switch
                            v-if="scope.row.show && scope.row.showGrouping && (reportDataColumns.filter(x=>x.calcColumnTotal||x.calcRowTotal).length>0)"
                            v-model="scope.row.calcGroupingTotal"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="Отображаемое название" prop="groupingTotalDisplayName"
                                       header-align="center" min-width="300">
                        <template slot-scope="scope">
                          <el-input size="mini"
                                    v-if="scope.row.calcGroupingTotal && scope.row.show && scope.row.showGrouping && (reportDataColumns.filter(x=>x.calcColumnTotal||x.calcRowTotal).length>0)"
                                    v-model="scope.row.groupingTotalDisplayName"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column label="Положение" prop="groupingTotalPosition"
                                       header-align="center" min-width="100">
                        <template slot-scope="scope">
                          <el-select size="mini"
                                     v-if="scope.row.calcGroupingTotal && scope.row.show && scope.row.showGrouping && (reportDataColumns.filter(x=>x.calcColumnTotal||x.calcRowTotal).length>0)"
                                     v-model="scope.row.groupingTotalPosition">
                            <el-option label="В ячейке слева"
                                       value="left_cell">
                            </el-option>
                            <el-option label="В ячейке справа"
                                       value="right_cell">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column label="Положение" prop="showOrder" header-align="center"
                                     min-width="100">
                      <template slot-scope="scope">
                        <el-button v-if="scope.row.show"
                                   @click="handleShowUp(scope.row, 'column_header')">
                          <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button v-if="scope.row.show"
                                   @click="handleShowDown(scope.row, 'column_header')">
                          <i class="el-icon-arrow-down"></i>
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="Сортировка значений" header-align="center">
                      <el-table-column label="Направление" header-align="center" min-width="270">
                        <template slot-scope="scope">
                          <el-select size="mini" v-if="scope.row.supportsSorting"
                                     v-model="scope.row.showSort" popper-class="sort_direction">
                            <el-option label="Не используется"
                                       value="">
                            </el-option>
                            <el-option label="Основная, по возрастанию"
                                       value="primary_asc">
                            </el-option>
                            <el-option label="Основная, по убыванию"
                                       value="primary_desc">
                            </el-option>
                            <el-option label="Дополнительная, по возрастанию"
                                       value="secondary_asc">
                            </el-option>
                            <el-option label="Дополнительная, по убыванию"
                                       value="secondary_desc">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column label="Пустые в начале" header-align="center">
                        <template slot-scope="scope">
                          <el-switch v-if="scope.row.showSort"
                                     v-model="scope.row.showSortEmptyFirst"></el-switch>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column v-if="report.meta.showColumnHeaderTitles"
                                     label="Отображаемое название" header-align="center"
                                     min-width="500">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.showDisplayName" size="mini"></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="hasRowHeaderColumns" class="label--width-middle string-headers"
                             label="Заголовки строк">
                  <el-form-item prop="showRowHeaderTitles" label="Отображать названия строк">
                    <el-switch v-model="report.meta.showRowHeaderTitles"
                               id="show_row_header_titles"></el-switch>
                  </el-form-item>
                  <el-form-item prop="rowHeaderShowEmpty"
                                label="Отображать пустые (нулевые) значения">
                    <el-switch v-model="report.meta.rowHeaderShowEmpty"
                               id="show_row_header_empty"></el-switch>
                  </el-form-item>
                  <el-table :data="rowHeaderColumns"
                            style="width: 100%" max-height="450">
                    <el-table-column label="Таблица" prop="tableDisplayName" fixed
                                     header-align="center" min-width="150">
                    </el-table-column>
                    <el-table-column label="Наименование" prop="displayNameWithDescription" fixed
                                     header-align="center" min-width="150">
                    </el-table-column>
                    <el-table-column label="Отображение" prop="show" header-align="center"
                                     min-width="150">
                      <template slot-scope="scope">
                        <el-switch v-model="scope.row.show"></el-switch>
                      </template>
                    </el-table-column>
                    <el-table-column label="Группировка" header-align="center" min-width="180">
                      <template slot-scope="scope">
                        <el-select v-if="scope.row.show" v-model="scope.row.showGrouping"
                                   size="mini" popper-class="grouping_type">
                          <el-option label="Не используется"
                                     value="">
                          </el-option>
                          <el-option label="Объединение"
                                     value="merged">
                          </el-option>
                          <el-option label="Заголовок"
                                     value="header">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column label="Подытог" header-align="center">
                      <el-table-column label="Вычислить" prop="calcGroupingTotal"
                                       header-align="center" min-width="150">
                        <template slot-scope="scope">
                          <el-switch
                            v-if="scope.row.show && scope.row.showGrouping && (reportDataColumns.filter(x=>x.calcColumnTotal||x.calcRowTotal).length>0)"
                            v-model="scope.row.calcGroupingTotal"></el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column label="Отображаемое название" prop="groupingTotalDisplayName"
                                       header-align="center" min-width="150">
                        <template slot-scope="scope">
                          <el-input
                            v-if="scope.row.calcGroupingTotal && scope.row.show && scope.row.showGrouping && (reportDataColumns.filter(x=>x.calcColumnTotal||x.calcRowTotal).length>0)"
                            v-model="scope.row.groupingTotalDisplayName" size="mini"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column label="Положение" prop="groupingTotalPosition"
                                       header-align="center" min-width="150">
                        <template slot-scope="scope">
                          <el-select size="mini"
                                     v-if="scope.row.calcGroupingTotal && scope.row.show && scope.row.showGrouping && (reportDataColumns.filter(x=>x.calcColumnTotal||x.calcRowTotal).length>0)"
                                     v-model="scope.row.groupingTotalPosition">
                            <el-option label="В ячейке сверху"
                                       value="left_cell">
                            </el-option>
                            <el-option label="В ячейке снизу"
                                       value="right_cell">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column label="Положение" prop="showOrder" header-align="center"
                                     min-width="150">
                      <template slot-scope="scope">
                        <el-button v-if="scope.row.show"
                                   @click="handleShowUp(scope.row, 'row_header')">
                          <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button v-if="scope.row.show"
                                   @click="handleShowDown(scope.row, 'row_header')">
                          <i class="el-icon-arrow-down"></i>
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="Сортировка значений" header-align="center">
                      <el-table-column label="Направление" header-align="center" min-width="300">
                        <template slot-scope="scope">
                          <el-select size="mini" v-if="scope.row.supportsSorting"
                                     v-model="scope.row.showSort" popper-class="sort_direction">
                            <el-option label="Не используется"
                                       value="">
                            </el-option>
                            <el-option label="Основная, по возрастанию"
                                       value="primary_asc">
                            </el-option>
                            <el-option label="Основная, по убыванию"
                                       value="primary_desc">
                            </el-option>
                            <el-option label="Дополнительная, по возрастанию"
                                       value="secondary_asc">
                            </el-option>
                            <el-option label="Дополнительная, по убыванию"
                                       value="secondary_desc">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column label="Пустые в начале" header-align="center"
                                       min-width="150">
                        <template slot-scope="scope">
                          <el-switch v-if="scope.row.showSort"
                                     v-model="scope.row.showSortEmptyFirst"></el-switch>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column v-if="report.meta.showRowHeaderTitles"
                                     label="Отображаемое название" min-width="500"
                                     header-align="center">
                      <template slot-scope="scope">
                        <el-input size="mini" v-model="scope.row.showDisplayName"></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="Расширенная настройка отображения" :style="{ height: cardHeight}"
                         name="all_view_setting">
              <el-form-item label="Использовать шаблон">
                <el-switch v-model="report.meta.templateEnabled"></el-switch>
              </el-form-item>
              <el-form-item v-if="report.meta.templateEnabled" label="Шаблон:"
                            class="upload_file_field">
                <el-upload action=""
                           ref="templateFile"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           :multiple="false"
                           :limit="1"
                           :http-request="handleTemplateUpload"
                           :on-remove="handleTemplateRemove"
                           :auto-upload="true">
                  <el-button slot="trigger" size="small" type="primary">Выбрать файл</el-button>
                </el-upload>
                <span class="upload-succses"
                      :class="{'upload-error': !report.meta.templateFile}">{{
                    report.meta.templateFile ? 'Шаблон выбран' : 'Шаблон не выбран'
                  }}</span>
                <el-button v-if="report.meta.templateFile" type="text" icon="el-icon-download"
                           @click="handleTemplateDownload">Скачать
                </el-button>
                <el-button type="text" icon="el-icon-download" @click="handleEmptyTemplateDownload">
                  Скачать (пустой)
                </el-button>
              </el-form-item>
              <el-form-item v-if="report.meta.templateEnabled && hasColumnHeaderColumns"
                            label="Отображение заголовков столбцов">
                <el-switch v-model="report.meta.templateShowColumnHeaders"></el-switch>
              </el-form-item>
              <el-form-item v-if="report.meta.templateEnabled && hasRowHeaderColumns"
                            label="Отображение заголовков строк">
                <el-switch v-model="report.meta.templateShowRowHeaders"></el-switch>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="Предпросмотр" name="previewTab" :style="{ height: cardHeight}">
              <el-form label-width="400px">                
                <el-row :gutter="10" type="flex" style="margin-top:0px">
                  <el-col :span="24" class="ml-2 filter--container custom-label-right" :xs="24" :sm="24" :md="24" :lg="14" :xl="12">
                    <el-form-item v-for="item in showingFilterColumns"
                                  :label="item.filterDisplayName + ':'" :key="item.fullName">
                      <div>
                        <el-select v-model="item.filterIsNullValue">
                          <el-option label='Конкретное значение'
                                    value=''>
                          </el-option>
                          <el-option label='Любое значение'
                                    value='not null'>
                          </el-option>
                          <el-option label='Значение отсутствует'
                                    value='null'>
                          </el-option>
                        </el-select>
                      </div>
                      <div v-if="!item.filterIsNullValue">
                        <div v-if="item.filterType=='in'">
                          <div v-if="getCatalogException(item.fullName)" class="error">
                            {{ getCatalogException(item.fullName) }}
                          </div>
                          <!--el-select v-else v-model="item.filterMultiValue" multiple filterable popper-class="search-select">
                            <el-option v-for="catalogItem in getCatalogItems(item.fullName)"
                                      :key="catalogItem.id"
                                      :label="catalogItem.name"
                                      :value="catalogItem.id">
                            </el-option>
                          </el-select-->
                          <search-input
                            v-model="item.filterMultiValue"
                            multiple
                            :searchList=item.catalog
                            return-prop="id"
                            label="name"
                            size="large"
                            :allow-create="false"
                            :show-when-init="true"
                            :start-length="item.catalog.length > 200 ? 3 : 0"
                          />
                        </div>
                        <div v-if="item.filterType=='like'">
                          <el-input v-model="item.filterSingleValue"></el-input>
                        </div>
                        <div v-if="item.filterType=='equals'">
                          <el-date-picker v-if="item.dataType=='datetime'"
                                          v-model="item.filterSingleValue" format="dd.MM.yyyy"
                                          placeholder="дд.мм.гггг" v-mask="'##.##.####'"
                                          :picker-options="datePickerOptions"></el-date-picker>
                          <el-radio-group v-else-if="item.dataType=='boolean'"
                                          v-model="item.filterSingleValue">
                            <el-radio :label="null">Любой</el-radio>
                            <el-radio :label="'true'">Да</el-radio>
                            <el-radio :label="'false'">Нет</el-radio>
                          </el-radio-group>
                          <el-input-number v-else-if="item.dataType=='numeric'"
                                          v-model="item.filterSingleValue"></el-input-number>
                          <el-input v-else v-model="item.filterSingleValue"></el-input>
                        </div>
                        <div v-if="item.filterType=='between'">
                          <el-date-picker v-if="item.dataType=='datetime'"
                                          v-model="item.filterFromValue" format="dd.MM.yyyy"
                                          placeholder="дд.мм.гггг" v-mask="'##.##.####'"
                                          :picker-options="datePickerOptions"></el-date-picker>
                          <el-input-number v-else-if="item.dataType=='numeric'"
                                          v-model="item.filterFromValue"></el-input-number>
                          <el-input v-else v-model="item.filterFromValue"></el-input>
                          <span>&nbsp;-&nbsp;</span>
                          <el-date-picker v-if="item.dataType=='datetime'" v-model="item.filterToValue"
                                          format="dd.MM.yyyy" placeholder="дд.мм.гггг"
                                          v-mask="'##.##.####'"
                                          :picker-options="datePickerOptions"></el-date-picker>
                          <el-input-number v-else-if="item.dataType=='numeric'"
                                          v-model="item.filterToValue"></el-input-number>
                          <el-input v-else v-model="item.filterToValue"></el-input>
                        </div>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <el-row type="flex" justify="right">
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="12">
                  <div class="text-right">
                    <el-button @click="handleGetPreviewFile" :loading="fileLoading">Выгрузить
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </el-col>
    </el-row>
    <!--<el-row :gutter="20" type="flex" justify="center" class="card__button">
      <el-col :span="24">
        <el-button @click="handleBack">Вернуться</el-button>
        <el-button type="success" @click="handleSave">Сохранить</el-button>
      </el-col>
    </el-row>-->

    <el-dialog :visible.sync="filterSqlModalVisible" title="Настройка фильтра-справочника">
      <el-form :model="filterSqlModalModel">
        <el-form-item prop="filterCatalogSql" label="Запрос (по умолчанию)">
          <el-input type="textarea"
                    autosize readonly
                    v-model="filterSqlModalModel.filterCatalogSql">
          </el-input>
        </el-form-item>
        <el-form-item prop="filterCatalogCustomSql" label="Запрос (настройка)">
          <el-input type="textarea"
                    autosize clearable
                    v-model="filterSqlModalModel.filterCatalogCustomSql">
          </el-input>
        </el-form-item>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="24">
            <div class="text-right">
              <el-button @click="handleFilterSqlClose">Закрыть</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import reportsApi from '@/api/reports';
import tableResize from '@/mixins/tableResize.js';
import identityApi from '@/api/identity';
import io from '@/utilities/fileIO';
import SaveIcon from '@/assets/icons/save.svg';
import CancelIcon from '@/assets/icons/cancel.svg';
import dateHelpers from '@/mixins/dateHelpers';
import SearchInput from '@/components/Ui/SearchInput';
import { mapGetters } from 'vuex';
import organizations from '@/api/nsi/organizations';

export default {
  name: 'Report',
  props: ['reportId'],
  mixins: [dateHelpers, tableResize],
  components: { SaveIcon, SearchInput, CancelIcon },
  data() {
    return {
      loading: false,
      fileLoading: false,
      title: '',
      isCardResize: true,
      tableSizeConstant: 50,
      active_main_tab: 'info',
      allowedOrganizations: [],
      allowedOrganizationsСache: [],
      allowedOrganizationEdit: false,
      isNew: true,
      report: {
        allowedOrganizations: [],
        meta: {
          columnTotalDisplayName: 'Итого',
          filterSummaryDisplayName: 'Параметры',
          tables: [],
          columns: [],
          dataSql: '',
          columnHeaderSql: '',
          rowHeaderSql: '',
        },
      },
      roleGroups: [],
      dbSchema: {},
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
        ],
      },

      filterSqlModalVisible: false,
      filterSqlModalModel: {},

      filterCatalogs: [],

      initialReport: null
    };
  },

  watch: {
    'report.meta.tables': {
      handler() {
        // console.log('report.meta.tables changed');
        if (this.report.meta.tables) {
          const actualColumns = [];
          const maxShowOrder = this.getMaxShowOrder();
          const maxFilterOrder = this.getMaxFilterOrder();
          this.report.meta.tables.forEach((tableFullName) => {
            this.dbSchema.tables.filter((x) => x.fullName === tableFullName).forEach((table) => {
              table.columns.forEach((column) => {
                const actualColumn = {
                  tableFullName: table.fullName,
                  fullName: column.fullName,
                  tableDisplayName: table.displayName,
                  displayName: column.displayName,
                  displayNameWithDescription: column.additionalDescription ? `${column.displayName} (${column.additionalDescription})` : column.displayName,

                  supportsReportData: column.supportsReportData,
                  supportsSorting: column.supportsSorting,
                  supportsFiltering: column.supportsFiltering,
                  supportsSumAvg: column.supportsSumAvg,
                  supportsMinMax: column.supportsMinMax,
                  supportsCount: column.supportsCount,
                  supportsAggregation: column.supportsAggregation,
                  supportsGrouping: column.supportsGrouping,

                  filterDescription: column.filterDescription,
                  filterType: column.filterType,
                  dataType: column.dataType,
                  filterCatalogSql: column.filterCatalogSql,
                  supportsFilterCatalogSql: column.supportsFilterCatalogSql,
                  supportsFilterEqualsOrIn: column.supportsFilterEqualsOrIn,

                  catalog: [],
                };

                const existingColumns = this.report.meta.columns.filter((x) => x.fullName === column.fullName);
                if (existingColumns.length === 0) {
                  actualColumn.reportDataMode = '';

                  actualColumn.show = true;
                  actualColumn.showOrder = maxShowOrder + actualColumns.length;
                  actualColumn.showDisplayName = `${actualColumn.tableDisplayName} - ${actualColumn.displayName}`;
                  actualColumn.showGrouping = '';
                  actualColumn.showSort = '';
                  actualColumn.showSortEmptyFirst = false;

                  actualColumn.calcRowPercent = false;
                  actualColumn.calcRowTotal = false;
                  actualColumn.calcRowTotalPercent = false;
                  actualColumn.calcColumnPercent = false;
                  actualColumn.calcColumnTotal = false;
                  actualColumn.calcColumnTotalPercent = false;
                  actualColumn.calcGroupingTotal = false;
                  actualColumn.groupingTotalPosition = 'same_cell';
                  actualColumn.groupingTotalDisplayName = 'Подытог';
                  actualColumn.rowTotalDisplayName = `Итого - ${actualColumn.showDisplayName}`;
                  actualColumn.percentPosition = 'same_cell';
                  actualColumn.percentDisplayName = '%';
                  actualColumn.filter = false;
                  actualColumn.filterShow = false;
                  actualColumn.filterHasDefaultValue = false;
                  actualColumn.filterDefaultSingleValue = '';
                  actualColumn.filterDefaultMultiValue = [];
                  actualColumn.filterDefaultFromValue = '';
                  actualColumn.filterDefaultToValue = '';
                  actualColumn.filterDefaultIsNullValue = '';
                  actualColumn.filterOrder = maxFilterOrder + actualColumns.length;
                  actualColumn.filterDisplayName = `${actualColumn.tableDisplayName} - ${actualColumn.displayName}`;
                  actualColumn.filterCatalogCustomSql = '';
                  actualColumn.filterIncludeInReport = false;

                  actualColumn.aggregate = '';
                } else {
                  actualColumn.reportDataMode = existingColumns[0].reportDataMode;

                  actualColumn.show = existingColumns[0].show;
                  actualColumn.showOrder = existingColumns[0].showOrder;
                  actualColumn.showDisplayName = existingColumns[0].showDisplayName;
                  actualColumn.showGrouping = existingColumns[0].showGrouping;
                  actualColumn.showSort = existingColumns[0].showSort;
                  actualColumn.showSortEmptyFirst = existingColumns[0].showSortEmptyFirst;

                  actualColumn.calcRowPercent = existingColumns[0].calcRowPercent;
                  actualColumn.calcRowTotal = existingColumns[0].calcRowTotal;
                  actualColumn.calcRowTotalPercent = existingColumns[0].calcRowTotalPercent;
                  actualColumn.calcColumnPercent = existingColumns[0].calcColumnPercent;
                  actualColumn.calcColumnTotal = existingColumns[0].calcColumnTotal;
                  actualColumn.calcColumnTotalPercent = existingColumns[0].calcColumnTotalPercent;
                  actualColumn.calcGroupingTotal = existingColumns[0].calcGroupingTotal;
                  actualColumn.groupingTotalPosition = existingColumns[0].groupingTotalPosition;
                  actualColumn.groupingTotalDisplayName = existingColumns[0].groupingTotalDisplayName;
                  actualColumn.rowTotalDisplayName = existingColumns[0].rowTotalDisplayName;
                  actualColumn.percentPosition = existingColumns[0].percentPosition;
                  actualColumn.percentDisplayName = existingColumns[0].percentDisplayName;

                  actualColumn.filter = existingColumns[0].filter;
                  actualColumn.filterShow = existingColumns[0].filterShow;
                  actualColumn.filterHasDefaultValue = existingColumns[0].filterHasDefaultValue;
                  actualColumn.filterDefaultSingleValue = existingColumns[0].filterDefaultSingleValue;
                  actualColumn.filterDefaultMultiValue = existingColumns[0].filterDefaultMultiValue;
                  actualColumn.filterDefaultFromValue = existingColumns[0].filterDefaultFromValue;
                  actualColumn.filterDefaultToValue = existingColumns[0].filterDefaultToValue;
                  actualColumn.filterDefaultIsNullValue = existingColumns[0].filterDefaultIsNullValue;
                  actualColumn.filterOrder = existingColumns[0].filterOrder;
                  actualColumn.filterDisplayName = existingColumns[0].filterDisplayName;
                  actualColumn.filterCatalogCustomSql = existingColumns[0].filterCatalogCustomSql;
                  actualColumn.filterIncludeInReport = existingColumns[0].filterIncludeInReport;
                  actualColumn.filterType = existingColumns[0].filterType;

                  actualColumn.aggregate = existingColumns[0].aggregate;
                }

                actualColumn.filterSingleValue = '';
                actualColumn.filterMultiValue = [];
                actualColumn.filterFromValue = '';
                actualColumn.filterToValue = '';

                actualColumns.push(actualColumn);
              });
            });
          });
          this.report.meta.columns = actualColumns;
        }
      },
    },
    'report.meta.columns': {
      async handler() {
         await this.getSql();
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('identity', ['isIaLevel', 'userHasRoleGroup', 'user']),
    canPublish() {
      return this.isIaLevel();
    },
    filterColumns() {
      return this.getFilterColumns();
    },
    isCanGrantAccess() {
      return this.userHasRoleGroup('ReportsEdit') && this.reportId;
    },
    showingFilterColumns() {
      return this.getShowingFilterColumns();
    },
    reportDataColumns() {
      return this.getReportDataColumns('data');
    },
    columnHeaderColumns() {
      return this.getReportDataColumns('column_header');
    },
    rowHeaderColumns() {
      return this.getReportDataColumns('row_header');
    },
    hasColumnHeaderColumns() {
      return this.getReportDataColumns('column_header').length > 0;
    },
    hasRowHeaderColumns() {
      return this.getReportDataColumns('row_header').length > 0;
    },
    allowedOrganizationsWithoutOwnDC() {
      return this.allowedOrganizations.filter((item) => item.id !== this.user.organizationId);
    },
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.tableHeight = this.getTableHeight();
    this.cardHeight = this.getCardHeight();
    this.loading = false;
  },
  methods: {
    async getSql() {
      const sqlResponse = await reportsApi.getSql(this.report.meta);
      this.report.meta.dataSql = sqlResponse.data.dataSql;
      this.report.meta.columnHeaderSql = sqlResponse.data.columnHeaderSql;
      this.report.meta.rowHeaderSql = sqlResponse.data.rowHeaderSql;
    },
    allowedOrganizationEditHandler() {
      this.allowedOrganizationEdit = true;
      this.allowedOrganizationsСache = [...this.report.allowedOrganizations];
    },
    allowedOrganizationCancelHandler() {
      this.allowedOrganizationEdit = false;
      this.report.allowedOrganizations = [...this.allowedOrganizationsСache];
      this.allowedOrganizationsСache = [];
    },
    async allowedOrganizationSaveHandler() {
      this.loading = true;
      try {
        await reportsApi.updateReportAccess(this.reportId, this.report.allowedOrganizations);
        this.$message({
          type: 'success',
          message: 'Сохранено успешно',
        });
      } catch (err) {
        this.$message({
          type: 'error',
          message: 'Ошибка при сохранении',
        });
      }
      this.allowedOrganizationEdit = false;
      this.loading = false;
    },
    async getData() {
      const roleGroupsResponse = await identityApi.getRoleGroups();
      this.roleGroups = roleGroupsResponse.data.items;

      const dbSchemaResponse = await reportsApi.getDBSchema();
      this.dbSchema = dbSchemaResponse.data;

      this.isNew = !this.reportId;
      if (!this.isNew) {
        this.title = 'Редактирование отчета';
        const reportResponse = await reportsApi.getSingle(this.reportId);
        this.report = reportResponse.data;

        const allowRes = await organizations.getRelatives();
        if (allowRes.data) {
          this.allowedOrganizations = allowRes.data;
        }
        
        this.report.allowedOrganizations = this.report.allowedOrganizations
          .filter((item2) => this.allowedOrganizationsWithoutOwnDC.some((item1) => item1.id === item2));

        await this.getSql();
        this.initialReport = cloneDeep(this.report);
          
        // if(!this.isIaLevel){
        //   this.published=false;
        // }
      } else {
        this.title = 'Создание отчета';
      }
    },
    async handleSave() {
      try {
        const response = await reportsApi.save(this.report);
        this.$message({
          type: 'success',
          message: 'Сохранено успешно',
        });
        if (this.isNew) {
          this.$router.push({ name: 'ReportEdit', params: { reportId: response.data } });
          this.$router.go();
        } else {
          this.getData();
        }
      } catch (err) {
        this.$message({
          type: 'error',
          message: err.message,
        });
      }
    },
    handleBack() {
      if (!this.hasChanges()) {
        this.$router.push({ name: 'ReportsEdit' });
      } else {
        this.$confirm('Все несохраненные данные будут утеряны. Продолжить?', 'Подтверждение', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(() => {
          this.$router.push({ name: 'ReportsEdit' });
        }).catch(() => {
        });
      }
    },
    hasChanges() {
      if (!this.initialReport) {
        return true;
      }

      const actualReport = cloneDeep(this.report);
      if (this.initialReport.name !== actualReport.name) {
        return true;
      }
      if (this.initialReport.roleGroupId !== actualReport.roleGroupId) {
        return true;
      }
      if (this.initialReport.published !== actualReport.published) {
        return true;
      }

      if (this.initialReport.meta.templateFile && actualReport.meta.templateFile) {
        if (this.initialReport.meta.templateFile.length !== actualReport.meta.templateFile.length) {
          return true;
        }
      } else if (this.initialReport.meta.templateFile && !actualReport.meta.templateFile) {
        return true;
      } else if (!this.initialReport.meta.templateFile && actualReport.meta.templateFile) {
        return true;
      }
      if (this.initialReport.meta.templateEnabled !== actualReport.meta.templateEnabled) {
        return true;
      }
      if (this.initialReport.meta.templateShowColumnHeaders !== actualReport.meta.templateShowColumnHeaders) {
        return true;
      }
      if (this.initialReport.meta.templateShowRowHeaders !== actualReport.meta.templateShowRowHeaders) {
        return true;
      }
      if (this.normalizeSql(this.initialReport.meta.dataSql) !== this.normalizeSql(actualReport.meta.dataSql)) {
        return true;
      }
      if (this.normalizeSql(this.initialReport.meta.dataCustomSql) !== this.normalizeSql(actualReport.meta.dataCustomSql)) {
        return true;
      }
      if (this.normalizeSql(this.initialReport.meta.columnHeaderSql) !== this.normalizeSql(actualReport.meta.columnHeaderSql)) {
        return true;
      }
      if (this.normalizeSql(this.initialReport.meta.columnHeaderCustomSql) !== this.normalizeSql(actualReport.meta.columnHeaderCustomSql)) {
        return true;
      }
      if (this.normalizeSql(this.initialReport.meta.rowHeaderSql) !== this.normalizeSql(actualReport.meta.rowHeaderSql)) {
        return true;
      }
      if (this.normalizeSql(this.initialReport.meta.rowHeaderCustomSql) !== this.normalizeSql(actualReport.meta.rowHeaderCustomSql)) {
        return true;
      }
      if (this.initialReport.meta.filterSummaryDisplayName !== actualReport.meta.filterSummaryDisplayName) {
        return true;
      }
      if (this.initialReport.meta.showDataTitles !== actualReport.meta.showDataTitles) {
        return true;
      }
      if (this.initialReport.meta.columnTotalDisplayName !== actualReport.meta.columnTotalDisplayName) {
        return true;
      }
      if (this.initialReport.meta.columnTotalPosition !== actualReport.meta.columnTotalPosition) {
        return true;
      }
      if (this.initialReport.meta.rowTotalPosition !== actualReport.meta.rowTotalPosition) {
        return true;
      }
      if (this.initialReport.meta.columnHeaderShowEmpty !== actualReport.meta.columnHeaderShowEmpty) {
        return true;
      }
      if (this.initialReport.meta.rowHeaderShowEmpty !== actualReport.meta.rowHeaderShowEmpty) {
        return true;
      }
      if (this.initialReport.meta.showColumnHeaderTitles !== actualReport.meta.showColumnHeaderTitles) {
        return true;
      }
      if (this.initialReport.meta.showRowHeaderTitles !== actualReport.meta.showRowHeaderTitles) {
        return true;
      }
      if (this.initialReport.meta.tables.length !== actualReport.meta.tables.length) {
        return true;
      }
      for (let i = 0; i < this.initialReport.meta.tables.length; i++) {
        if (this.initialReport.meta.tables[i] !== actualReport.meta.tables[i]) {
          return true;
        }
      }
      if (this.initialReport.meta.columns.length !== actualReport.meta.columns.length) {
        return true;
      }
      for (let i = 0; i < this.initialReport.meta.columns.length; i++) {
        const initialColumn = this.initialReport.meta.columns[i];
        const actualColumn = actualReport.meta.columns[i];

        if (initialColumn.reportDataMode !== actualColumn.reportDataMode) {
          return true;
        }
        if (initialColumn.show !== actualColumn.show) {
          return true;
        }
        if (initialColumn.showOrder !== actualColumn.showOrder) {
          return true;
        }
        if (initialColumn.showDisplayName !== actualColumn.showDisplayName) {
          return true;
        }
        if (initialColumn.showGrouping !== actualColumn.showGrouping) {
          return true;
        }
        if (initialColumn.showSort !== actualColumn.showSort) {
          return true;
        }
        if (initialColumn.showSortEmptyFirst !== actualColumn.showSortEmptyFirst) {
          return true;
        }
        if (initialColumn.calcRowPercent !== actualColumn.calcRowPercent) {
          return true;
        }
        if (initialColumn.calcRowTotal !== actualColumn.calcRowTotal) {
          return true;
        }
        if (initialColumn.calcRowTotalPercent !== actualColumn.calcRowTotalPercent) {
          return true;
        }
        if (initialColumn.calcColumnPercent !== actualColumn.calcColumnPercent) {
          return true;
        }
        if (initialColumn.calcColumnTotal !== actualColumn.calcColumnTotal) {
          return true;
        }
        if (initialColumn.calcColumnTotalPercent !== actualColumn.calcColumnTotalPercent) {
          return true;
        }
        if (initialColumn.calcGroupingTotal !== actualColumn.calcGroupingTotal) {
          return true;
        }
        if (initialColumn.groupingTotalPosition !== actualColumn.groupingTotalPosition) {
          return true;
        }
        if (initialColumn.groupingTotalDisplayName !== actualColumn.groupingTotalDisplayName) {
          return true;
        }
        if (initialColumn.rowTotalDisplayName !== actualColumn.rowTotalDisplayName) {
          return true;
        }
        if (initialColumn.percentPosition !== actualColumn.percentPosition) {
          return true;
        }
        if (initialColumn.percentDisplayName !== actualColumn.percentDisplayName) {
          return true;
        }
        if (initialColumn.filter !== actualColumn.filter) {
          return true;
        }
        if (initialColumn.filterShow !== actualColumn.filterShow) {
          return true;
        }
        if (initialColumn.filterHasDefaultValue !== actualColumn.filterHasDefaultValue) {
          return true;
        }
        if (initialColumn.filterDefaultSingleValue !== actualColumn.filterDefaultSingleValue) {
          return true;
        }
        if (JSON.stringify(initialColumn.filterDefaultMultiValue) !== JSON.stringify(actualColumn.filterDefaultMultiValue)) {
          return true;
        }
        if (this.normalizeObj(initialColumn.filterDefaultFromValue) !== this.normalizeObj(actualColumn.filterDefaultFromValue)) {
          return true;
        }
        if (this.normalizeObj(initialColumn.filterDefaultToValue) !== this.normalizeObj(actualColumn.filterDefaultToValue)) {
          return true;
        }
        if (initialColumn.filterDefaultIsNullValue !== actualColumn.filterDefaultIsNullValue) {
          return true;
        }
        if (initialColumn.filterOrder !== actualColumn.filterOrder) {
          return true;
        }
        if (initialColumn.filterDisplayName !== actualColumn.filterDisplayName) {
          return true;
        }
        if (this.normalizeSql(initialColumn.filterCatalogCustomSql) !== this.normalizeSql(actualColumn.filterCatalogCustomSql)) {
          return true;
        }
        if (initialColumn.filterIncludeInReport !== actualColumn.filterIncludeInReport) {
          return true;
        }
        if (initialColumn.filterType !== actualColumn.filterType) {
          return true;
        }
        if (initialColumn.aggregate !== actualColumn.aggregate) {
          return true;
        }
      }
      return false;
    },
    normalizeSql(sql) {
      if (!sql) {
        return '';
      }

      return sql.replace(/[\n\r]+/g, '');
    },
    normalizeObj(obj) {
      if (!obj) {
        return '';
      }

      return obj.toString();
    },
    handleFilterUp(column) {
      const filterColumns = this.getFilterColumns();
      const columnIndex = filterColumns.indexOf(column);
      const otherColumnIndex = columnIndex - 1;
      if (otherColumnIndex < 0) {
        return;
      }
      const otherColumn = filterColumns[otherColumnIndex];
      const temp = column.filterOrder;
      column.filterOrder = otherColumn.filterOrder;
      otherColumn.filterOrder = temp;
    },

    handleFilterDown(column) {
      const filterColumns = this.getFilterColumns();
      const columnIndex = filterColumns.indexOf(column);
      const otherColumnIndex = columnIndex + 1;
      if (otherColumnIndex >= filterColumns.length) {
        return;
      }
      const otherColumn = filterColumns[otherColumnIndex];
      const temp = column.filterOrder;
      column.filterOrder = otherColumn.filterOrder;
      otherColumn.filterOrder = temp;
    },

    async handleFilterHasDefaultChange(filterColumn) {
      if (filterColumn.filterHasDefaultValue && (filterColumn.filterType === 'in')) {
        await this.loadFilterCatalogs();
      }
    },

    handleShowUp(column, reportDataMode) {
      const reportDataColumns = this.getReportDataColumns(reportDataMode);
      const columnIndex = reportDataColumns.indexOf(column);
      const otherColumnIndex = columnIndex - 1;
      if (otherColumnIndex < 0) {
        return;
      }
      const otherColumn = reportDataColumns[otherColumnIndex];
      const temp = column.showOrder;
      column.showOrder = otherColumn.showOrder;
      otherColumn.showOrder = temp;
    },

    handleShowDown(column, reportDataMode) {
      const reportDataColumns = this.getReportDataColumns(reportDataMode);
      const columnIndex = reportDataColumns.indexOf(column);
      const otherColumnIndex = columnIndex + 1;
      if (otherColumnIndex >= reportDataColumns.length) {
        return;
      }
      const otherColumn = reportDataColumns[otherColumnIndex];
      const temp = column.showOrder;
      column.showOrder = otherColumn.showOrder;
      otherColumn.showOrder = temp;
    },

    handleFilterSqlOpen(column) {
      this.filterSqlModalModel = column;
      this.filterSqlModalVisible = true;
    },

    handleFilterSqlClose() {
      this.filterSqlModalModel = {};
      this.filterSqlModalVisible = false;
    },

    async handleTabClick(tab) {
      if (tab.name === 'previewTab') {
        await this.loadFilterCatalogs();
        const showingFilterColumns = this.getShowingFilterColumns();

        for (let i = 0; i < showingFilterColumns.length; i++) {
          const filterColumn = showingFilterColumns[i];
          if (filterColumn.filterHasDefaultValue) {
            this.$set(filterColumn, 'filterMultiValue', filterColumn.filterDefaultMultiValue);
            this.$set(filterColumn, 'filterSingleValue', filterColumn.filterDefaultSingleValue);
            this.$set(filterColumn, 'filterFromValue', filterColumn.filterDefaultFromValue);
            this.$set(filterColumn, 'filterToValue', filterColumn.filterDefaultToValue);
            this.$set(filterColumn, 'filterIsNullValue', filterColumn.filterDefaultIsNullValue);
          } else {
            this.$set(filterColumn, 'filterMultiValue', []);
            this.$set(filterColumn, 'filterSingleValue', '');
            this.$set(filterColumn, 'filterFromValue', '');
            this.$set(filterColumn, 'filterToValue', '');
            this.$set(filterColumn, 'filterIsNullValue', '');
          }
          // console.log(filterColumn);
        }
      }
      if (tab.name === 'filterTab') {
        const filterColumns = this.getFilterColumns();
        if (filterColumns.filter((x) => x.filterHasDefaultValue && (x.filterType === 'in')).length > 0) {
          await this.loadFilterCatalogs();
        }
      }
    },

    async loadFilterCatalogs() {
      // console.log('loadFilterCatalogs');
      this.loading = true;
      const catalogsResponse = await reportsApi.getPreviewCatalogs(this.report.meta);
      this.filterCatalogs = catalogsResponse.data.items;
      this.filterCatalogs.forEach((x) => {
        this.filterColumns.find((col) => col.fullName === x.columnFullName).catalog = x.items;
      });

      this.loading = false;
    },

    async handleGetPreviewFile() {
      this.fileLoading = true;
      this.$message({
        type: 'success',
        message: 'Формирование отчета запущено, пожалуйста, подождите'
      });

      const filterColumns = cloneDeep(this.getFilterColumns());
      for (let i = 0; i < filterColumns.length; i++) {
        const filterColumn = filterColumns[i];
        if (filterColumn.dataType === 'datetime') {
          if (filterColumn.filterSingleValue) {
            filterColumn.filterSingleValueOriginal = moment(filterColumn.filterSingleValue).format('DD.MM.YYYY');
            filterColumn.filterSingleValue = this.dateSetter(filterColumn.filterSingleValue);
          }
          if (filterColumn.filterFromValue) {
            filterColumn.filterFromValueOriginal = moment(filterColumn.filterFromValue).format('DD.MM.YYYY');
            filterColumn.filterFromValue = this.dateSetter(filterColumn.filterFromValue);
          }
          if (filterColumn.filterToValue) {
            filterColumn.filterToValueOriginal = moment(filterColumn.filterToValue).format('DD.MM.YYYY');
            filterColumn.filterToValue = this.dateSetter(filterColumn.filterToValue);
          }
        }
      }
      const response = await reportsApi.downloadPreviewFile(this.report.meta, filterColumns);

      this.fileLoading = false;

      if (response.data.exceptionDetails) {
        this.$alert(`При формировании отчета произошла ошибка: ${response.data.exceptionDetails}`, 'Ошибка');
      } else {
        io.downloadFile(response.data.content, response.data.contentType, response.data.fileName);
      }
    },

    getMaxFilterOrder() {
      const filterColumns = this.getFilterColumns();
      if (filterColumns.length > 0) {
        return filterColumns[filterColumns.length - 1].filterOrder;
      }
      return 0;
    },

    getFilterColumns() {
      if (this.report.meta.columns) {
        return this.report.meta.columns.filter((x) => x.filter === true).sort((a, b) => a.filterOrder - b.filterOrder);
      }
      return [];
    },

    getShowingFilterColumns() {
      if (this.report.meta.columns) {
        return this.report.meta.columns.filter((x) => x.filter === true && x.filterShow === true).sort((a, b) => a.filterOrder - b.filterOrder);
      }
      return [];
    },

    getMaxShowOrder() {
      const reportDataColumns = this.getReportDataColumns();
      if (reportDataColumns.length > 0) {
        return reportDataColumns[reportDataColumns.length - 1].showOrder;
      }
      return 0;
    },

    getReportDataColumns(reportDataMode) {
      if (this.report.meta.columns) {
        if (!reportDataMode) {
          return this.report.meta.columns.filter((x) => x.reportDataMode).sort((a, b) => a.showOrder - b.showOrder);
        } else {
          return this.report.meta.columns.filter((x) => x.reportDataMode === reportDataMode).sort((a, b) => a.showOrder - b.showOrder);
        }
      }
      return [];
    },

    getCatalogItems(columnFullName) {
      const catalogs = this.filterCatalogs.filter((x) => x.columnFullName === columnFullName);
      // console.log(catalogs);
      if (catalogs.length !== 0) {
        return catalogs[0].items;
      }
      return [];
    },

    getCatalogException(columnFullName) {
      const catalogs = this.filterCatalogs.filter((x) => x.columnFullName === columnFullName);
      if (catalogs.length !== 0) {
        return catalogs[0].exceptionDetails;
      }
      return null;
    },

    handleTemplateDownload() {
      if (this.report.meta.templateFile) {
        io.downloadFile(this.report.meta.templateFile, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'Шаблон.xlsx');
      }
    },

    async handleEmptyTemplateDownload() {
      const response = await reportsApi.downloadEmptyTemplateFile(this.report.meta);
      if (response.data.exceptionDetails) {
        this.$alert(`При формировании отчета произошла ошибка: ${response.data.exceptionDetails}`, 'Ошибка');
      } else {
        io.downloadFile(response.data.content, response.data.contentType, response.data.fileName);
      }
    },

    handleTemplateUpload(file) {
      io.readBytes(file.file, (bytes) => {
        this.report.meta.templateFile = bytes;
        this.$refs.templateFile.clearFiles();
      });
    },

    handleTemplateRemove() {
      this.report.meta.templateFile = null;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
};
</script>

<style scoped>
.el-select--large {
  width: 100%;
}

.error {
  color: red;
}
</style>
